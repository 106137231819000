body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700 !important;
}

[data-baseweb='popover'] {
  z-index: 2;
}

*::-webkit-scrollbar {
  -webkit-border-radius: 5px;
  background-color: transparent;
  border-radius: 5px;
  height: 10px;
  transition: background-color 0.2s linear;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  background: #aaa;
  background-clip: padding-box;
  border: 2px solid transparent;
  border-radius: 5px;
  transition: background-color 0.2s linear;
}

.boxFirework {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 100;
}

.dropzone {
  background-color: #F5F5F5;
  border: dashed 4px transparent;
  border-radius: 20px;
  margin: 10px auto 30px;
  padding: 10px;
  width: 80%;
  transition: background-color 0.3s;
  height: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone.dropzone1{
  background-color: #92D050;
}

.dropzone.dropzone2{
  background-color: #FFC000;
}

.dropzone.dropzone8{
  background-color: #92D050;
}

.dropzone.dropzone7{
  background-color: #92D050;
}

.dropzone.more {
  background-color: #ccc !important;
}

.activeDrag {
  background-color: #55b955;
}

.dropzone.activeDrag.green {
  background-color: green;
}

.dropzone.activeDrag.red {
  background-color: red;
}

.dropzone.green {
  background-color: rgb(196, 255, 196);
}

.dropzone.red {
  background-color: rgb(252, 198, 198);
}

.dropzone.green .check {
  background-color: green;
}

.activeImageSC {
  position: relative;
  background: white;
  border-radius: 0.25em;
  padding: 10px 0px;
  width: 80%;
}

.dropzone .flecha-derecha{
  position: absolute;
  top: 50%;
  left: 97%;
  transform: translateY(-50%);
  font-size: 110px;
  z-index: 1;
  color: #EB0F00;
  text-shadow: 3px 3px 3px #00000067;
}

.dropzone .flecha-abajo{
  position: absolute;
  top: 66%;
  left: 50%;
  font-size: 110px;
  transform: translateX(-50%);
  z-index: 1;
  color: #F5BD00;
  text-shadow: 3px 3px 3px #00000067;
}

.dropzone .flecha-derecha::before {
  content: 'NO';
  position: absolute;
  color: #575757;
  font-size: 21px;
  top: 30%;
  left: 31%;
  text-shadow: none;
  font-weight: 600;
}

.dropzone .flecha-abajo::before {
  content: 'SI';
  position: absolute;
  color: #575757;
  font-size: 21px;
  right: -9px;
  top: 43%;
  text-shadow: none;
  font-weight: 600;
}


.dropzone.isLup .check {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  font-weight: bold;
  font-family: 'Poppins';
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #ffff;
  opacity: 0.3;
}

.dropzone.isLup:nth-child(odd) .check {
  background-color: red;
}

.drag-drop {
  height: 200px !important;
}

.dropzone.border-error {
  border: 2px solid red;
}


footer {}

footer p {
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
}

@media (max-width : 1100px) {
  .drag-drop {
    height: 180px !important;
  }
}



.efect_blur {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
  backdrop-filter: blur(5.8px);
  -webkit-backdrop-filter: blur(5.8px);
  z-index: 2;
}

.filter {
  filter: grayscale(1);
}


div[data-baseweb="toaster"]{

  z-index: 10;
}

@media(max-width:1350px){
  .dropzone{
    height: 120px;
  }
}